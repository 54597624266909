.setting {
  &-payment {
    &__accounted {
      &-term {
        opacity: 0;
        transition: all .5s;
      }
      & ~ .setting-payment__accounted-term {
        opacity: 1;
      }
    }
  }
}