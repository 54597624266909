html,
body {
	height: 100% !important;
}
//ボタンアイコン
$icons: document, edit, trash, add;
@each $icon in $icons {
	.icon-#{$icon} {
		&,
		&__white {
			&::before {
				content: '';
				display: inline-block;
				height: 16px;
				margin: 0 0.5em 0 0;
				width: 16px;
			}
		}
		&::before {
			background-image: url('../assets/common/#{$icon}16x16.png');
		}
		&__white::before {
			background-image: url('../assets/common/#{$icon}16x16_white.png');
		}
	}
}

//改行コード
.convert-text {
	white-space: pre-wrap;
	word-wrap: break-word;
}